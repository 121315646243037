<template>
    <DashboardWrapper>
        <div class="container mx-auto">
            <div class="mx-4 sm:mx-8">
                <h2 class="text-white pb-4 mb-4 uppercase text-xl font-extrabold">
                    Club Instellingen
                </h2>
                     
                <div class="flex flex-col md:flex-row md:items-center md:py-6"> 
                    <div class="w-32 h-32 self-center">
                        <img src="@/assets/images/logo/emblem.svg" draggable="false" alt="profile image" class="shadow rounded-full w-32 h-32 border-none" />
                        <!-- <div class="border-2 border-gray-400 rounded-full max-w-full h-auto">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path d="M248,8C111,8,0,119,0,256S111,504,248,504,496,393,496,256,385,8,248,8Zm0,96a88,88,0,1,1-88,88A88,88,0,0,1,248,104Zm0,344a191.61,191.61,0,0,1-146.5-68.2C120.3,344.4,157.1,320,200,320a24.76,24.76,0,0,1,7.1,1.1,124.67,124.67,0,0,0,81.8,0A24.76,24.76,0,0,1,296,320c42.9,0,79.7,24.4,98.5,59.8A191.61,191.61,0,0,1,248,448Z" class="fill-color-second"/><path d="M248,280a88,88,0,1,0-88-88A88,88,0,0,0,248,280Zm48,40a24.76,24.76,0,0,0-7.1,1.1,124.67,124.67,0,0,1-81.8,0A24.76,24.76,0,0,0,200,320c-42.9,0-79.7,24.4-98.5,59.8,68.07,80.91,188.84,91.32,269.75,23.25A192,192,0,0,0,394.5,379.8C375.7,344.4,338.9,320,296,320Z" class="fill-color-main"/></svg>
                        </div> -->
                    </div>
                    <div class="text-white text-center pt-4 md:pt-0 md:ml-10 font-bold">
                        <h2 class="text-xl font-semibold text-white">Clubnaam</h2>
                    </div>
                    <div>
                        <div class="py-6 md:py-0 ml-0 md:ml-20 relative flex justify-around w-full md:space-x-12">
                            <div class="flex-1 justify-center my-auto text-center">
                                <span class="block text-scorelitorange font-bold text-lg">174</span>
                                <span class="block text-gray-400 text-sm">Leden</span>    
                            </div>

                            <div class="flex-1 justify-center my-auto text-center">
                                <span class="block text-scorelitorange font-bold text-lg">16</span>
                                <span class="block text-gray-400 text-sm">Coaches</span>    
                            </div>

                            <div class="flex-1 justify-center my-auto text-center">
                                <span class="block text-scorelitorange font-bold text-lg">11</span>
                                <span class="block text-gray-400 text-sm">Teams</span>    
                            </div>
                        </div>
                    </div> 
                </div>
                
                <div class="grid grid-cols-1 md:grid-cols-2 md:gap-x-20 lg:gap-x-32 gap-y-8 md:gap-y-12 mt-4 md:mt-6">
                    <div class="w-full">
                        <FormSection title="Algemeen" hasSaveButton="true">
                            <TextInput label="Clubnaam" value="Clubnaam" theme="dark" class="mt-4 md:mt-6"/>
                            <ImageInput label="Clublogo" theme="dark" class="mt-4 md:mt-6"/>
                            <TextInput label="E-mailadres" value="ClubEmail@gmail.com" theme="dark" class="mt-4 md:mt-6"/>
                            <SelectInput label="Sport" theme="dark" class="mt-4 md:mt-6"/>
                            <TextAreaInput label="Omschrijving" value="Beschrijving van de club" theme="dark" class="mt-4 md:mt-6"/>
                        </FormSection>
                    </div>
                    <div class="w-full">
                        <FormSection title="Factuurgegevens" hasSaveButton="true">
                            <div class="grid grid-cols-2 gap-x-6">
                                <TextInput label="Straat" value="Stationsweg" theme="dark" class="mt-4 md:mt-6"/>
                                <TextInput label="Huisnummer" value="10" theme="dark" class="mt-4 md:mt-6"/>
                                <TextInput label="Stad" value="Groningen" theme="dark" class="mt-4 md:mt-6"/>
                                <TextInput label="Provincie" value="Groningen" theme="dark" class="mt-4 md:mt-6"/>
                                <TextInput label="Postcode" value="9000AA" theme="dark" class="mt-4 md:mt-6"/>
                                <TextInput label="Land" value="Nederland" theme="dark" class="mt-4 md:mt-6"/>
                            </div>
                            <TextInput label="IBAN" value="NL00BANK0000000000" theme="dark" class="mt-4 md:mt-6"/>
                            <TextInput label="BIC" value="12345678" theme="dark" class="mt-4 md:mt-6"/>
                        </FormSection>
                    </div>
                    <div class="w-full">
                        <FormSection title="Overeenkomsten">
                            <div class="mt-5 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div class="shadow overflow-hidden border-gray-200">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Document
                                                    </th>
                                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Geaccepteerd op
                                                    </th>
                                                    <th scope="col" class="relative px-6 py-3">
                                                        <span class="sr-only">Edit</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="">
                                                    <td class="px-6 py-4 whitespace-nowrap text-sm  text-white">
                                                        Gebruikersovereenkomst
                                                    </td>
                                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-white">
                                                        Datum
                                                    </td>
                                                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                        <a href="#" class="text-scorelitorange hover:text-scorelitred">Bekijken</a>
                                                    </td>
                                                </tr>

                                                <tr class="">
                                                    <td class="px-6 py-4 whitespace-nowrap text-sm  text-white">
                                                        Verwerkersovereenkomst
                                                    </td>
                                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-white">
                                                        Datum
                                                    </td>
                                                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                        <a href="#" class="text-scorelitorange hover:text-scorelitred">Bekijken</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </FormSection>
                    </div>
                </div>
            </div>
        </div>
    </DashboardWrapper>
</template>

<script>
import DashboardWrapper from "@/views/wrappers/Dashboard";
import FormSection from "@/views/wrappers/partials/FormSection";
import TextInput from "@/components/forms/TextInput";
import SelectInput from "@/components/forms/SelectInput";
import TextAreaInput from "@/components/forms/TextAreaInput";
import ImageInput from "@/components/forms/ImageInput";

export default {
    name: "ClubSettings",
    components: {
        DashboardWrapper,
        FormSection,
        TextInput,
        SelectInput,
        TextAreaInput,
        ImageInput,
    },
}
</script>